import { toast } from 'react-toastify';
import { Loader, Message } from 'semantic-ui-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { confirmERPTicket } from 'http/hubspot';
import { getResourceTemplate, getResources } from 'http/productEngine';
import { getEntriesInPeriodByAccountNumber } from 'http/erpBroker';
import erpSystems from 'util/erpSystems';
import { sendAccounplantApprovalEmail } from 'http/email';
import { getTaxonomyPresentationOrder, listTaxonomyTags } from 'http/taxonomyParser';
import { getAccountPlansFact } from 'util/FactMapUtil';
import useUser from 'util/useUser';

import { OVERSKRIFT } from './accountTypes';
import { formatAccounts } from './formatAccounts';
import getPeriodDatesAsISO from './getPeriodDatesAsISO';
import PublishmentsViewer from './PublishmentsViewer';
import ApprovalModule from './ApprovalModule';
import AccountPlanTable from './AccountPlanTable';
import PostEntriesBooker from './PostEntriesBooker';

const groupHasTypeHomogeneity = (accountplan, accountsNumbersToCheck) => {
    const accountNumberToType = accountplan.reduce((acc, { number, accountType }) => {
        return { ...acc, [number]: accountType };
    }, {});

    const [firstAccount, ...restOfTheAccounts] = accountsNumbersToCheck;

    const firstAccountType = accountNumberToType[firstAccount];

    return restOfTheAccounts.every(accountNumber => {
        return accountNumberToType[accountNumber] === firstAccountType;
    });
};

// "c_erp_data.go" in model-editor contains the options
// that can be exported from the erpdata node
const getAccountplansOptionByKey = (productMetadata, accountplansFact, optionKey) => {
    if (!accountplansFact.id) return null;
    if (!productMetadata) return null;

    const factMetadata = productMetadata.facts[accountplansFact.id];
    if (!factMetadata) return null;

    const optionValue = factMetadata.options[optionKey];
    if (!optionValue) return null;

    return optionValue;
};

const getAccountplansJSONOption = (productMetadata, accountplansFact, optionKey) => {
    const optionValueAsJSON = getAccountplansOptionByKey(productMetadata, accountplansFact, optionKey);
    if (!optionValueAsJSON) return null;

    return JSON.parse(optionValueAsJSON);
};

const ClosingSheet = ({ values, productMetadata, pagePrefix, currentPeriod, onChange: triggerFactUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filterQuery, setFilterQuery] = useState('');
    const [showZeroAccounts, setShowZeroAccounts] = useState(false);
    const [showDecimalAccounts, setshowDecimalAccounts] = useState(false);
    const [showOnlyErrorLines, setShowOnlyErrorLines] = useState(false);
    const [showOnlyUncheckedLines, setShowOnlyUncheckedLines] = useState(false);
    const [selectedAccounts, setSelectedAccounts] = useState(new Set());
    const [resourceData, setResourceData] = useState(null);
    const [taxonomyTags, setTaxonomyTags] = useState(null);
    const user = useUser();

    const toggleAccountSelection = accountNumber => {
        const selectionCopy = new Set(selectedAccounts);
        if (selectionCopy.has(accountNumber)) {
            selectionCopy.delete(accountNumber);
        } else {
            selectionCopy.add(accountNumber);
        }
        setSelectedAccounts(selectionCopy);
    };

    const accountplansFact = useMemo(() => {
        return getAccountPlansFact(values) || {};
    }, [values]);

    const xbrlTagsGroupedBySumTags = useMemo(() => {
        const selectedTaxonomyPresentationGroups = getAccountplansJSONOption(
            productMetadata,
            accountplansFact,
            'selectedTaxonomyPresentationGroupsJSON',
        );

        if (!selectedTaxonomyPresentationGroups) {
            return null;
        }

        return getAccountplansJSONOption(productMetadata, accountplansFact, 'xbrlTagsGroupedBySumTagsJSON');
    }, [accountplansFact, productMetadata]);

    useEffect(() => {
        if (resourceData) return;
        if (taxonomyTags) return;
        
        const resourceFetchJobs = [];
        const resourceConfiguration = getAccountplansJSONOption(productMetadata, accountplansFact, 'resourceConfigurationJSON');

        if (resourceConfiguration) {
            const { resourceTemplateSlug, mappableXbrlTags } = resourceConfiguration;
            
            resourceFetchJobs.push(async () => {
                const [resources, resourceTemplate] = await Promise.all([
                    getResources(resourceTemplateSlug, productMetadata.id),
                    getResourceTemplate(resourceTemplateSlug),
                ]);
    
                const formattedResources = resources.map(({ values, slug }) => {
                    return {
                        label: values[resourceTemplate.titleProperty],
                        slug,
                    };
                });
    
                setResourceData({
                    resources: formattedResources,
                    template: resourceTemplate,
                    mappableXbrlTags: new Set(mappableXbrlTags),
                });
            });
        }

        const selectedTaxonomyPresentationGroups = getAccountplansJSONOption(
            productMetadata,
            accountplansFact,
            'selectedTaxonomyPresentationGroupsJSON',
        );

        if (productMetadata.taxonomy && selectedTaxonomyPresentationGroups) {
            resourceFetchJobs.push(async () => {
                const [taxonomyTags, taxonomyPresentationOrder] = await Promise.all([
                    listTaxonomyTags(productMetadata.taxonomy),
                    getTaxonomyPresentationOrder(productMetadata.taxonomy),
                ]);

                const idToName = {};
                for (const tag of taxonomyTags) {
                    idToName[tag.id] = tag.name;
                }

                let nextOrder = 0;
                const order = {};

                const recordOrderFrom = (node) => {
                    const nodeName = idToName[node.id];
                    if (!(nodeName in order)) {
                        order[nodeName] = nextOrder++;
                    }
                    for (const child of node.children) {
                        recordOrderFrom(child);
                    }
                };

                for (const presentationOrderGroup of taxonomyPresentationOrder) {
                    const shouldUsePresentationGroup = selectedTaxonomyPresentationGroups.includes(presentationOrderGroup.name);
                    if (shouldUsePresentationGroup) {
                        recordOrderFrom(presentationOrderGroup.order);
                    }
                }

                setTaxonomyTags({ tags: taxonomyTags, order: order });
            });
        }

        if (resourceFetchJobs.length === 0) {
            return;
        }

        setLoading(true);

        Promise.all(resourceFetchJobs.map(job => job()))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [accountplansFact, productMetadata, resourceData, taxonomyTags]);

    const [modelXbrlTags, modelAccountantCodes] = useMemo(() => {
        const { requiredXbrlTags, requiredAccountantCodes } = productMetadata;

        return [
            new Set(requiredXbrlTags?.filter(x => x)),
            new Set(requiredAccountantCodes?.filter(x => x)),
        ];
    }, [productMetadata]);
    
    const auditorsDocumentationInfo = useMemo(() => {
        const auditorsDocumentationIsActiveFactID = getAccountplansOptionByKey(productMetadata, accountplansFact, 'auditorsDocumentationIsActiveNodeID');
        
        const info = {
            auditorsDocumentationIsActive: values[auditorsDocumentationIsActiveFactID]?.value?.boolean || false,
            materialityLevel: 0,
            questionGroups: [],
            loginIDOfConfirmingUser: '',
            loggedInUserIsConfirmingUser: false,

            questionAnswers: {},
            customGroupQuestions: {},
        };
        
        if (!info.auditorsDocumentationIsActive) {
            return info; // early out
        }
        
        const accountplansFactValue = accountplansFact?.value || {};
        info.questionAnswers = accountplansFactValue.questionAnswers || {};
        info.questionGroupInfoTable = accountplansFactValue.questionGroupInfoTable || {};

        const materialityLevelFactID = getAccountplansOptionByKey(productMetadata, accountplansFact, 'materialityLevelNodeID');
        info.materialityLevel = values[materialityLevelFactID]?.value?.number || 0;

        info.questionGroups = getAccountplansJSONOption(productMetadata, accountplansFact, 'closingSheetQuestionGroupsJSON') || [];

        const confirmingLoginFactID = getAccountplansOptionByKey(productMetadata, accountplansFact, 'confirmingLoginNodeID');
        info.loginIDOfConfirmingUser = values[confirmingLoginFactID]?.value?.string || '';
        info.loggedInUserIsConfirmingUser = info.loginIDOfConfirmingUser === user.login?.id;

        return info;
    }, [productMetadata, accountplansFact, values, user]);

    const [accountplan, actionsRequired] = useMemo(() => {
        const factValue = accountplansFact.value || {};

        const accounts = factValue.accounts || [];
        const lastyear = factValue.lastyear || [];
        const checkedAccounts = factValue.checkedAccounts || {};
        const draftPostEntries = factValue.draftPostEntries || [];
        const childToMasterAccountTable = factValue.childToMasterAccountTable || {};
        const accountToResourceTable = factValue.accountToResourceTable || {};

        const resourceMappableXbrlTags = resourceData?.mappableXbrlTags;

        const formattedAccounts = formatAccounts({
            currentYear: accounts,
            lastYear: lastyear,
            modelXbrlTags,
            modelAccountantCodes,
            checkedAccounts,
            draftPostEntries,
            childToMasterAccountTable,
            accountToResourceTable,
            resourceMappableXbrlTags,
            auditorsDocumentationInfo,
        });
        
        // infuse auditors documentation related fields into accounts
        // and collect information about the different question groups
        if (auditorsDocumentationInfo.auditorsDocumentationIsActive) {
            const collectedGroups = {};

            const { questionGroupInfoTable, materialityLevel, questionAnswers } = auditorsDocumentationInfo;

            for (const account of formattedAccounts) {
                const associatedGroup = auditorsDocumentationInfo.questionGroups.find(group => {
                    return group.triggeringXbrlTags.includes(account.xbrlMapping.chosenTag);
                });
                
                if (!associatedGroup) {
                    continue;
                }

                const groupIsInitialized = associatedGroup.id in collectedGroups;
                if (!groupIsInitialized) {
                    collectedGroups[associatedGroup.id] = {
                        groupID: associatedGroup.id,
                        groupData: associatedGroup,
                        state: questionGroupInfoTable[associatedGroup.id],
                        connectedAccounts: [],
                        questionsAndAnswers: associatedGroup.questions.map(question => {
                            return {
                                ...question,
                                answerValue: questionAnswers[question.id] || '',
                            };
                        }),
                        countUnansweredQuestions() {
                            const allQuestions = [
                                ...this.questionsAndAnswers,
                                ...(this.state?.customGroupQuestions || []),
                            ];

                            return allQuestions.filter(question => !question.answerValue).length;
                        },
                        countRelevantAccountsThatAreUnchecked() {
                            const accountsThatAreRelevantAndUnchecked = this.connectedAccounts.filter(account => {
                                return account.amountIsAboveMaterialityLevel && !account.isChecked;
                            });

                            return accountsThatAreRelevantAndUnchecked.length;
                        },
                    };
                }

                collectedGroups[associatedGroup.id].connectedAccounts.push(account);

                const currentYearAmountIsAboveMaterialityLevel = Math.abs(Number(account.currentYearAmount)) >= materialityLevel;
                const lastYearAmountIsAboveMaterialityLevel = Math.abs(Number(account.lastYearAmount)) >= materialityLevel;
                account.amountIsAboveMaterialityLevel = currentYearAmountIsAboveMaterialityLevel || lastYearAmountIsAboveMaterialityLevel;
                account.associatedQuestionsGroup = collectedGroups[associatedGroup.id];
                account.disabled = collectedGroups[associatedGroup.id]?.state?.isApprovedByConfirmingLogin;
            }
        }

        const alreadyCountedQuestionGroupIds = new Set();

        let totalActionsRequired = 0;

        for (const account of formattedAccounts) {
            account.actionsRequiredCount = 0;

            account.actionsRequiredCount += Number(account.accountantCodeMapping.hasWarning);
            account.actionsRequiredCount += Number(account.xbrlMapping.hasWarning);

            account.actionsRequiredCount += Number(account.accountantCodeMapping.hasCriticalError);
            account.actionsRequiredCount += Number(account.xbrlMapping.hasCriticalError);

            account.actionsRequiredCount += Number(account.resourceMappingMissing);

            const { associatedQuestionsGroup, amountIsAboveMaterialityLevel } = account;
            const shouldCountGroupUnansweredQuestions = (
                associatedQuestionsGroup &&
                !alreadyCountedQuestionGroupIds.has(associatedQuestionsGroup.groupID) &&
                amountIsAboveMaterialityLevel
            );

            if (shouldCountGroupUnansweredQuestions) {
                account.actionsRequiredCount += associatedQuestionsGroup.countUnansweredQuestions();
                alreadyCountedQuestionGroupIds.add(associatedQuestionsGroup.groupID);
            }

            totalActionsRequired += account.actionsRequiredCount;
        }

        return [formattedAccounts, totalActionsRequired];
    }, [accountplansFact, modelXbrlTags, modelAccountantCodes, resourceData, auditorsDocumentationInfo]);

    const filteredAcccounts = useMemo(() => {
        let accountsToShow = accountplan;

        // reset account selection on filters change
        setSelectedAccounts(new Set());

        const alwaysInclude = ({ accountType, masterAccountNumber }) => {
            if (accountType === OVERSKRIFT) return true;
            if (masterAccountNumber) return true;

            return false;
        };

        if (!showZeroAccounts) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                const { groupTotal } = acc;

                const currentYearAmount = groupTotal?.currentYear ?? Number(acc.currentYearAmount);
                const lastYearAmount = groupTotal?.lastYear ?? Number(acc.lastYearAmount);

                const amountIsZeroBothYears = (
                    currentYearAmount === 0 &&
                    lastYearAmount === 0
                );

                return amountIsZeroBothYears === false;
            });
        }
        
        if (showOnlyErrorLines) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.actionsRequiredCount > 0;
            });
        }

        if (showOnlyUncheckedLines) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.isChecked === false;
            });
        }

        if (filterQuery) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.text.toLowerCase().includes(filterQuery.toLowerCase());
            });
        }

        // hide child accounts of hidden master accounts
        const visibleMasterAccounts = new Set();
        accountsToShow.forEach(account => {
            if (account.childAccounts) visibleMasterAccounts.add(account.number);
        });

        accountsToShow = accountsToShow.filter(({ masterAccountNumber }) => {
            if (!masterAccountNumber) return true;
            return visibleMasterAccounts.has(masterAccountNumber);
        });

        return accountsToShow;
    }, [accountplan, showZeroAccounts, showOnlyErrorLines, showOnlyUncheckedLines, filterQuery]);
    
    const runERPAction = (actionData = {}) => {
        const productFact = productMetadata.facts[accountplansFact.id];
        const triggerFactID = productFact.options.trigger;
    
        return triggerFactUpdate(triggerFactID, { number: Date.now() }, actionData);
    };

    const updateMappings = accountsToPatch => {
        if (!groupHasTypeHomogeneity(accountplan, Object.keys(accountsToPatch))) {
            return toast.error('Det er ikke tilladt at klassificere konti på tværs af kontotype');
        }

        return runERPAction({ accountsToPatch });
    };

    const handleApproval = async note => {
        const { stakeholderEmail } = accountplansFact.value;

        // make sure the account plan is approved by a different person
        // than the one initiating the mapping request
        const shouldSendMail = user?.login?.loginEmail !== stakeholderEmail;

        await Promise.all([
            runERPAction({ doApproveAccountplan: true }),
            confirmERPTicket(productMetadata.id, productMetadata.year),
            shouldSendMail && sendAccounplantApprovalEmail({
                productLink: pagePrefix + '/index',
                note: note,
                email: stakeholderEmail,
                displayName: user.getDisplayName(),
            }),
        ]);
    };

    const userErpSystem = erpSystems[user.erp];
    const getEntriesByAccountNumber = useCallback(number => {
        if (userErpSystem === erpSystems.manual) {
            const archivedEntries = accountplansFact?.value?.archivedPostEntries || [];

            const relevantAcconuts = [];
            for (const entry of archivedEntries) {
                const relevantAffectedAccounts = entry.affectedAccounts.filter(account => {
                    return account.accountNumber === number;
                });

                relevantAffectedAccounts.forEach(account => relevantAcconuts.push({
                    amount: account.amount,
                    text: entry.text,
                    date: entry.date,
                    entryNumber: entry.entryNumber,
                }));
            }

            return Promise.resolve(relevantAcconuts);
        }

        if (!userErpSystem?.supportsClosingSheet) {
            return Promise.resolve([]);
        }

        const { from, to } = getPeriodDatesAsISO(currentPeriod);
        return getEntriesInPeriodByAccountNumber(number, from, to);
    }, [currentPeriod, userErpSystem, accountplansFact]);

    const draftPostEntriesAndRefetchAccountPlan = (specificEntryNumbersToArchive = undefined) => {
        const contextArgs = {
            shouldArchiveDraftPostEntries: true,
        };

        // if specificEntryNumbersToArchive is not provided, all post entries should be drafted
        if (specificEntryNumbersToArchive) {
            contextArgs.specificEntryNumbersToArchive = specificEntryNumbersToArchive
        }

        return runERPAction(contextArgs)
    };

    const addPostEntry = postEntryToAdd => {
        return runERPAction({
            postEntryToAdd,
        });
    };

    const updatePostEntry = ( entryNumber, postEntryToUpdate ) => {
        return runERPAction({
            postEntryToUpdate,
            entryNumber: entryNumber
        });
    };

    const addCustomQuestionToGroup = customQuestionInfo => {
        return runERPAction({
            customQuestionInfo,
        });
    };

    const deleteCustomQuestionFromGroup = (groupID, questionID) => {
        return runERPAction({
            customQuestionToDelete: {
                groupID,
                questionID,
            },
        });
    };

    const groupAccounts = (masterAccount, childAccounts = []) => {
        if (!groupHasTypeHomogeneity(accountplan, [masterAccount, ...childAccounts])) {
            return toast.error('Det er ikke tilladt at gruppere konti på tværs af kontotype');
        }

        return runERPAction({
            accountsToGroup: {
                masterAccount,
                childAccounts,
            },
        });
    };

    const renameGroup = (masterAccount, newName) => {
        return runERPAction({
            groupToRename: {
                masterAccount,
                newName,
            },
        });
    };

    const disbandAccountGroup = groupToDisband => {
        return runERPAction({
            groupToDisband,
        });
    };

    const updateAccountInfo = (accountNumber, newData) => {
        return runERPAction({
            accountToUpdate: {
                accountNumber,
                newData,
            },
        });
    };

    const removeAccountFromGroup = (accountNumberToRemoveFromGroup) => {
        return runERPAction({ accountNumberToRemoveFromGroup });
    };

    const deletePostEntriesByNumber = entryNumberToDelete => {
        return runERPAction({ entryNumberToDelete });
    };

    const toggleAccountChecked = accountNumberToToggle => {
        return runERPAction({ accountNumberToToggle });
    };

    const toggleQuestionGroupChecked = questionGroupIDToToggle => {
        return runERPAction({ questionGroupIDToToggle });
    };

    const setAccountResourceMapping = (accountNumber, resourceSlug) => {
        return runERPAction({
            accountResourceToSet: {
                accountNumber,
                resourceSlug,
            },
        });
    };

    const provideQuestionAnswers = questionAnswers => {
        return runERPAction({ questionAnswers })
    };

    if (loading) {
        return (
            <Loader
                inline='centered'
                size='huge'
                active
            />
        );
    }

    if (error) {
        return (
            <Message
                color='red'
                icon='warning sign'
                header='Der opstod en fejl'
                content='Prøv eventuelt at opdatere siden. Hvis fejlen bliver ved med at opstå, så kontakt os i supporten.'
            />
        );
    }

    const postEntries = accountplansFact.value?.draftPostEntries || [];
    const archivedPostEntries = accountplansFact.value?.archivedPostEntries || [];
    const accountInfoTable = accountplansFact.value?.accountInfoTable || {};
    const masterAccountNameOverrides = accountplansFact.value?.masterAccountNameOverrides || {};

    let resourceInfo;
    if (resourceData) {
        const accountToResourceTable = accountplansFact.value?.accountToResourceTable || {};

        resourceInfo = {
            ...resourceData,
            accountToResourceTable,
            setAccountResourceMapping,
        };
    }

    const renderTable = () => {
        return (
            <AccountPlanTable        
                setFilterQuery={setFilterQuery}
                showZeroAccounts={showZeroAccounts}
                setShowZeroAccounts={setShowZeroAccounts}
                showDecimalAccounts={showDecimalAccounts}
                setShowDecimalAccounts={setshowDecimalAccounts}
                showOnlyErrorLines={showOnlyErrorLines}
                setShowOnlyErrorLines={setShowOnlyErrorLines}
                showOnlyUncheckedLines={showOnlyUncheckedLines}
                setShowOnlyUncheckedLines={setShowOnlyUncheckedLines}
                actionsRequired={actionsRequired}
                filteredAcccounts={filteredAcccounts}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                toggleAccountSelection={toggleAccountSelection}
                updateMappings={updateMappings}
                groupAccounts={groupAccounts}
                renameGroup={renameGroup}
                disbandAccountGroup={disbandAccountGroup}
                modelXbrlTags={modelXbrlTags}
                modelAccountantCodes={modelAccountantCodes}
                accountplan={accountplan}
                lastYearEditable={accountplansFact.value?.lastYearEditable}
                getEntriesByAccountNumber={getEntriesByAccountNumber}
                addPostEntry={addPostEntry}
                updatePostEntry={updatePostEntry}
                accountInfoTable={accountInfoTable}
                updateAccountInfo={updateAccountInfo}
                fiscalPeriod={currentPeriod}
                removeAccountFromGroup={removeAccountFromGroup}
                deletePostEntriesByNumber={deletePostEntriesByNumber}
                toggleAccountChecked={toggleAccountChecked}
                masterAccountNameOverrides={masterAccountNameOverrides}
                postEntries={postEntries}
                resourceInfo={resourceInfo}
                provideQuestionAnswers={provideQuestionAnswers}
                addCustomQuestionToGroup={addCustomQuestionToGroup}
                deleteCustomQuestionFromGroup={deleteCustomQuestionFromGroup}
                toggleQuestionGroupChecked={toggleQuestionGroupChecked}
                taxYear={Number(productMetadata.year)}
                xbrlTagsGroupedBySumTags={xbrlTagsGroupedBySumTags}
                taxonomyTags={taxonomyTags}
                auditorsDocumentationInfo={auditorsDocumentationInfo}
            />
        );
    };

    const renderApprovalModule = () => {
        const { needsRevision, revised } = accountplansFact?.value || {};

        if (revised) return null;
        if (!needsRevision) return null;
        
        return <ApprovalModule onApproval={handleApproval} />;
    };

    const renderBookPostEntriesModule = () => {
        const fiscalPeriod = getPeriodDatesAsISO(currentPeriod);

        return (
            <PostEntriesBooker
                postEntries={postEntries}
                archivedPostEntries={archivedPostEntries}
                fiscalPeriod={fiscalPeriod}
                draftPostEntriesAndRefetchAccountPlan={draftPostEntriesAndRefetchAccountPlan}
                showDecimalAccounts={showDecimalAccounts}
            />
        );
    };

    return (
        <>
            {<PublishmentsViewer />}
            {renderTable()}
            {renderApprovalModule()}
            {renderBookPostEntriesModule()}
        </>
    );
};

export default ClosingSheet;
