import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import UploadImage from 'design/molecules/UploadImage';

const InlineImagePicker = ({ isLocked, trigger, editorID, onOverwrite,triggerReportRegeneration, overrideProp  }) => {
    const [open, setOpen] = useState(false);
    const [customImages, setCustomImages] = useState([]);

    const handleImageUploaded = (imageID) => {
        try {
                onOverwrite({
                    editorID: editorID,
                    namespace: 'buildingBlockOverrides',
                    newData: {backgroundImage: imageID} ,
                    shouldDelete: !imageID,
                });
                
                if (imageID) {
                    toast.success('Billede blev opdateret');
                } else {
                    toast.success('Billede blev fjernet');
                }
                setOpen(false);
            
        } catch (e) {
            toast.error(`Der opstod en fejl ved opdatering af billedet: ${e.message}`);
            console.error("Error uploading image:", e);
        }
    };

    const wrappedTrigger = React.cloneElement(trigger, {
        onClick: (e) => {
            if (!isLocked) {
                e.stopPropagation();
                setOpen(true);
            }
            
            if (trigger.props && trigger.props.onClick) {
                trigger.props.onClick(e);
            }
        },
        style: {
            ...trigger.props.style,
            cursor: isLocked ? 'default' : 'pointer'
        }
    });

    return (
        <Popup
            basic
            open={open}
            onClose={() => setOpen(false)}
            closeOnTriggerBlur={false}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={false}
            position='top center'
            trigger={wrappedTrigger}
            content={
                <div style={{ width: '350px', padding: '1em' }}>
                    <h3>Vælg billede</h3>
                    <UploadImage
                    mode="image"
                    updateUserLogo={false}
                    onImageUploaded={handleImageUploaded}
                    onLogoChanged={handleImageUploaded} 
                    customImages={customImages}
                    setCustomImages={setCustomImages}
                />
                    <div style={{ marginTop: '1em', textAlign: 'right' }}>
                        <Button basic onClick={() => setOpen(false)}>Annuller</Button>
                    </div>
                </div>
            }
        />
    );
};

export default InlineImagePicker;