import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Input,
    Modal,
    Pagination,
    Table,
    Popup,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getEntryAttachmentData, getAttachmentsForEntriesInPeriod } from 'http/erpBroker';
import { formatDate } from 'util/format/DateTime';
import { formatNumber, formatDecimalNumber } from 'util/format/Number';
import useUser from 'util/useUser';
import erpSystems from 'util/erpSystems';
import DatePicker from 'design/atoms/DatePicker';
import NumberInput from 'design/atoms/NumberInput';
import getPeriodDatesAsISO from './getPeriodDatesAsISO';
import { BALANCE, DRIFT } from './accountTypes';
import EditIcon from './EditIcon';
import DeleteIcon from './DeleteIcon';

import styles from './index.module.scss';

const makePostEntryAccount = (number = 0, amount) => {
    return {
        temporaryID: Math.random(),
        amount: amount || 0,
        accountNumber: number,
    };
};

const getAccountOptions = accountplan => {
    return accountplan
        .filter(({ accountType }) => {
            return accountType === DRIFT || accountType === BALANCE;
        })
        .map(({ number, text }) => {
            return {
                text: `${number} - ${text}`,
                key: number,
                value: number,
            };
        });
};

const updateAccountAtIndex = (index, propsToMutate = {}, affectedAccounts, onChange) => {
    const copy = [...affectedAccounts];
    copy[index] = { ...copy[index], ...propsToMutate };
    onChange(copy);
};

const validateEntry = (date, text, affectedAccounts, sumOfAccounts, showDecimalAccounts) => {
    if (!date) return 'Vælg en dato';
    if (!text) return 'Indtast en tekst';

    if (affectedAccounts.length < 2) return 'Vælg mindst to konti';

    const seenAccounts = new Set();
    for (let accIdx = 0; accIdx < affectedAccounts.length; accIdx++) {
        const { amount, accountNumber } = affectedAccounts[accIdx];

        if (!amount) return `Indtast beløb for linje #${accIdx + 1}`;
        if (!accountNumber) return `Vælg konto for linje #${accIdx + 1}`;
        if (seenAccounts.has(accountNumber))
            return `Konto ${accountNumber} er valgt mere end 1 gang`;

        seenAccounts.add(accountNumber);
    }

        if (sumOfAccounts !== 0) {
            return 'Beløbssum skal give 0';
        }

    return null;
};

const AttachmentsViewer = ({ accountNumber, attachments }) => {
    const [downloading, setDownloading] = useState(false);
    const [attachmentURL, setAttachmentURL] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const handleAttachmentClicked = () => {
        // assume only one attachment for now (handles the E-conomic case)
        const [firstAttachment] = attachments;

        if (attachmentURL) {
            setModalOpen(true);
            return;
        }

        setDownloading(true);

        const { attachmentID, entryID, attachmentType } = firstAttachment;

        getEntryAttachmentData(accountNumber, entryID, attachmentID, attachmentType)
            .then(data => {
                const pdfBlob = new Blob([Uint8Array.from(atob(data.base64Data), c => c.charCodeAt(0))], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setAttachmentURL(pdfUrl);
                setModalOpen(true);
            })
            .catch(() => toast.error('Bilaget kunne ikke indlæses'))
            .finally(() => setDownloading(false));
    };

    const icon = (
        <Icon
            onClick={() => handleAttachmentClicked()}
            name={downloading ? 'spinner' : 'attach'}
            loading={downloading}
            link={!downloading}
        />
    );

    return (
        <>
            <Popup
                position='top center'
                content='Se vedhæftet bilag'
                trigger={icon}
            />
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                <Modal.Content>
                    {/* assume attachment is PDF (for now) */}
                    {attachmentURL && (
                        <object
                            data={attachmentURL}
                            style={{ width: '100%', height: '600px' }}
                            children='PDF'
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' content='Luk' onClick={() => setModalOpen(false)} />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const AccountsPicker = ({ affectedAccounts, onChange, disabled, accountOptions, copyMode }) => {
    const [mounted, setMounted] = useState(false);
    const isCopyMode = copyMode ? true : false;
    useLayoutEffect(() => {
        setMounted(true);
    }, []);

    if (isCopyMode && copyMode && !mounted) {
        if (copyMode.amount.toString().includes('-')) {
            let reversedAmount = Math.abs(copyMode.amount);
            copyMode.amount = reversedAmount;
            affectedAccounts[0].amount = copyMode.amount;
            updateAccountAtIndex(0, { amount: reversedAmount }, affectedAccounts, onChange);
        } else if (copyMode.amount > 0 && !mounted) {
            let reversedAmount = -copyMode.amount;
            copyMode.amount = reversedAmount;
            affectedAccounts[0].amount = copyMode.amount;
            updateAccountAtIndex(0, { amount: reversedAmount }, affectedAccounts, onChange);
        }
    }

    const deleteAtIndex = idx => onChange(affectedAccounts.filter((_, i) => i !== idx));

    return affectedAccounts.map((account, idx) => {
        const isFirst = idx === 0;

        return (
            <Form.Group key={account.temporaryID}>
                <Form.Field disabled={disabled} width={8}>
                    {isFirst && <label>Konto</label>}
                    <Dropdown
                        selection
                        search
                        placeholder='Vælg en konto'
                        options={accountOptions}
                        value={account.accountNumber}
                        onChange={(_, { value }) =>
                            updateAccountAtIndex(
                                idx,
                                { accountNumber: value },
                                affectedAccounts,
                                onChange,
                            )
                        }
                        disabled={isFirst}
                    />
                </Form.Field>
                <Form.Field disabled={disabled} width={7}>
                    {isFirst && <label>Beløb</label>}
                    <NumberInput
                        autoFocus={mounted ? !isFirst : isFirst}
                        className={styles.numberInput}
                        value={isCopyMode && isFirst ? account.amount : ''}
                        onChange={amount =>
                            updateAccountAtIndex(idx, { amount }, affectedAccounts, onChange)
                        }
                    />
                </Form.Field>
                <Form.Field width={1}>
                    {isFirst && <label>&nbsp;</label>}
                    <Button
                        onClick={() => !isFirst && deleteAtIndex(idx)}
                        style={{
                            pointerEvents: isFirst ? 'none' : undefined,
                            opacity: isFirst ? 0.5 : 1,
                        }}
                        icon='trash'
                        basic
                        circular
                        tabIndex={-1}
                    />
                </Form.Field>
            </Form.Group>
        );
    });
};

const EditModal = ({ affectedAccounts, onChange, disabled, accountOptions }) => {
    const deleteAtIndex = idx => onChange(affectedAccounts.filter((_, i) => i !== idx));

    return affectedAccounts.map((entry, idx) => {
        const isFirst = idx === 0;
        return (
            <Form.Group key={entry.temporaryID}>
                <Form.Field disabled={disabled} width={8}>
                    {isFirst && idx === 0 && <label>Konto</label>}
                    <Dropdown
                        selection
                        search
                        placeholder='Vælg en konto'
                        options={accountOptions}
                        value={entry.accountNumber}
                        onChange={(_, { value }) =>
                            updateAccountAtIndex(
                                idx,
                                { accountNumber: value },
                                affectedAccounts,
                                onChange,
                            )
                        }
                    />
                </Form.Field>
                <Form.Field disabled={disabled} width={7}>
                    {isFirst && idx === 0 && <label>Beløb</label>}
                    <NumberInput
                        className={styles.numberInput}
                        onChange={amount =>
                            updateAccountAtIndex(idx, { amount }, affectedAccounts, onChange)
                        }
                        value={entry.amount}
                    />
                </Form.Field>
                <Form.Field width={1}>
                    {isFirst && <label>&nbsp;</label>}
                    <Button
                        onClick={() => !isFirst && deleteAtIndex(idx)}
                        style={{
                            pointerEvents: isFirst ? 'none' : undefined,
                            opacity: isFirst ? 0.5 : 1,
                        }}
                        icon='trash'
                        basic
                        circular
                    />
                </Form.Field>
            </Form.Group>
        );
    });
};

const EditPostEntryForm = ({
    entryToEdit,
    entryNumber,
    accountplan,
    updatePostEntry,
    setEditingPostEntry,
    onClose,
    fiscalPeriod,
    showDecimalAccounts,
}) => {
    const [fiscalStart, fiscalEnd] = [fiscalPeriod.start, fiscalPeriod.end].map(
        ({ year, month, day }) => {
            return Date.UTC(year, month - 1, day);
        },
    );

    const [date, setDate] = useState(fiscalEnd);
    const [text, setText] = useState(`OMP afslutningsark ${fiscalPeriod.taxYear}`);
    const [affectedAccounts, setAffectedAccounts] = useState([...entryToEdit]);

    const [working, setWorking] = useState(false);

    const accountOptions = useMemo(() => getAccountOptions(accountplan), [accountplan]);

    const sumOfAccounts = affectedAccounts.reduce((acc, { amount }) => {
        const amountNumber = parseFloat(amount) || 0;
        return Math.round((acc + amountNumber) * 100) / 100;
    }, 0);


    const roundedSum = Number(sumOfAccounts.toFixed(2));


    const updateEntries = async () => {
        const validationError = validateEntry(date, text, affectedAccounts, sumOfAccounts);
        if (validationError) {
            return toast.error(validationError);
        }

        setWorking(true);

        await updatePostEntry(entryNumber, {
            date: new Date(date).toISOString(),
            text,
            affectedAccounts,
        });
        setWorking(false);
        onClose();
    };

    return (
        <>
            <Modal.Content key={entryToEdit.temporaryID}>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Field disabled={working}>
                            <label>Dato</label>
                            <DatePicker
                                onChange={value => setDate(value + 1000 * 60 * 60 * 5)}
                                value={date}
                                minDate={fiscalStart}
                                maxDate={fiscalEnd}
                                placeholder='Vælg dato'
                            />
                        </Form.Field>
                        <Form.Field disabled={working}>
                            <label>Tekst</label>
                            <Input
                                onChange={(_, { value }) => setText(value)}
                                placeholder='Indtast tekst'
                                defaultValue={text}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ background: 'white', textAlign: 'left' }}>
                <Form style={{ margin: '0.5em' }}>
                    <EditModal
                        affectedAccounts={affectedAccounts}
                        onChange={newAffectedAccounts => setAffectedAccounts(newAffectedAccounts)}
                        accountOptions={accountOptions}
                        disabled={working}
                    />

                    <Form.Field style={{ textAlign: 'right' }}>
                        <Button
                            fluid
                            basic
                            icon='plus'
                            content='Tilføj linje'
                            onClick={() =>
                                setAffectedAccounts([...affectedAccounts, makePostEntryAccount()])
                            }
                        />
                    </Form.Field>
                    {affectedAccounts.some(acc => acc.amount !== 0) && (
                        <Form.Field style={{ textAlign: 'right' }}>
                            {sumOfAccounts === 0 && <Icon name='check circle' color='green' />}
                            Sum:{' '}
                            {showDecimalAccounts
                                ? formatDecimalNumber(roundedSum)
                                : formatNumber(sumOfAccounts)}{' '}
                            kr.
                        </Form.Field>
                    )}
                </Form>
            </Modal.Actions>

            <Modal.Actions>
                <Button
                    content='Annuller'
                    color='black'
                    onClick={() => setEditingPostEntry(false)}
                />
                <Button
                    primary
                    content='Opdater efterpostering'
                    icon='plus'
                    onClick={() => updateEntries(entryNumber, sumOfAccounts)}
                />
            </Modal.Actions>
        </>
    );
};

const CreatePostEntryForm = ({
    fiscalPeriod,
    onClose,
    addPostEntry,
    accountNumber,
    accountplan,
    entryNumber,
    currentEntries,
    mode,
    showDecimalAccounts,
    entryToCopy,
}) => {
    const [fiscalStart, fiscalEnd] = [fiscalPeriod.start, fiscalPeriod.end].map(
        ({ year, month, day }) => {
            return Date.UTC(year, month - 1, day);
        },
    );

    const [date, setDate] = useState(fiscalEnd);
    const [text, setText] = useState(`OMP afslutningsark ${fiscalPeriod.taxYear}`);
    const [affectedAccounts, setAffectedAccounts] = useState([
        makePostEntryAccount(accountNumber),
        makePostEntryAccount(),
    ]);

    const [working, setWorking] = useState(false);

    const accountOptions = useMemo(() => {
        return accountplan
            .filter(({ accountType }) => {
                return accountType === DRIFT || accountType === BALANCE;
            })
            .map(({ number, text }) => {
                return {
                    text: `${number} - ${text}`,
                    key: number,
                    value: number,
                };
            });
    }, [accountplan]);

    const sumOfAccounts = affectedAccounts.reduce((acc, { amount }) => {
        const amountNumber = parseFloat(amount) || 0;
        return acc + amountNumber;
    }, 0);
    const roundedSum = sumOfAccounts.toFixed(2);

    const createPostEntry = async () => {
        const validationError = validateEntry(date, text, affectedAccounts, sumOfAccounts);
        if (validationError) {
            return toast.error(validationError);
        }

        setWorking(true);
        await addPostEntry({
            date: new Date(date).toISOString(),
            text,
            affectedAccounts,
        });
        setWorking(false);
        onClose();
    };

    return (
        <>
            <Modal.Content>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Field disabled={working}>
                            <label>Dato</label>
                            <DatePicker
                                onChange={value => setDate(value + 1000 * 60 * 60 * 5)}
                                value={date}
                                minDate={fiscalStart}
                                maxDate={fiscalEnd}
                                placeholder='Vælg dato'
                            />
                        </Form.Field>
                        <Form.Field disabled={working}>
                            <label>Tekst</label>
                            <Input
                                onChange={(_, { value }) => setText(value)}
                                placeholder='Indtast tekst'
                                defaultValue={text}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ background: 'white', textAlign: 'left' }}>
                <Form style={{ margin: '0.5em' }}>
                    {mode === 'copy' ? (
                        <AccountsPicker
                            affectedAccounts={affectedAccounts}
                            onChange={affectedAccounts => setAffectedAccounts(affectedAccounts)}
                            accountOptions={accountOptions}
                            disabled={working}
                            currentEntries={currentEntries}
                            entryNumber={entryNumber}
                            copyMode={makePostEntryAccount(
                                entryToCopy.entryNumber,
                                entryToCopy.amount,
                            )}
                        />
                    ) : (
                        <AccountsPicker
                            affectedAccounts={affectedAccounts}
                            onChange={affectedAccounts => setAffectedAccounts(affectedAccounts)}
                            accountOptions={accountOptions}
                            disabled={working}
                        />
                    )}
                    <Form.Field style={{ textAlign: 'right' }}>
                        <Button
                            fluid
                            basic
                            icon='plus'
                            content='Tilføj linje'
                            onClick={() =>
                                setAffectedAccounts([...affectedAccounts, makePostEntryAccount()])
                            }
                        />
                    </Form.Field>
                    {affectedAccounts.some(acc => acc.amount !== 0) && (
                        <Form.Field style={{ textAlign: 'right' }}>
                            {sumOfAccounts === 0 && <Icon name='check circle' color='green' />}
                            Sum:{' '}
                            {formatDecimalNumber(roundedSum)}{' '}
                            kr.
                        </Form.Field>
                    )}
                </Form>
            </Modal.Actions>
            <Modal.Actions>
                <Button disabled={working} content='Annuller' color='black' onClick={onClose} />
                <Button
                    primary
                    disabled={working}
                    loading={working}
                    content='Opret efterpostering'
                    icon='plus'
                    onClick={() => createPostEntry(sumOfAccounts)}
                />
            </Modal.Actions>
        </>
    );
};

const tableHeaders = [
    {
        text: 'Bilagsnummer',
        property: 'entryNumber',
    },
    {
        text: 'Dato',
        property: 'date',
    },
    {
        text: 'Momskode',
        property: 'vatCode',
    },
    {
        text: 'Tekst',
        property: 'text',
    },
    {
        text: 'Beløb',
        property: 'amount',
        style: { textAlign: 'right' },
    },
    {
        style: { textAlign: 'right' },
    },
];

const EntriesViewer = ({
    disabled,
    fiscalPeriod,
    accountNumber,
    entriesFetcher,
    accountPostEntries,
    addPostEntry,
    accountplan,
    deletePostEntriesByNumber,
    unroundedCurrentYearAmount,
    currentYearAmount,
    updatePostEntry,
    postEntries,
    showDecimalAccounts,
}) => {
    const [loading, setLoading] = useState(true);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [error, setError] = useState(false);
    const [creatingPostEntry, setCreatingPostEntry] = useState(false);
    const [entries, setEntries] = useState([]);
    const [sortBy, setSortBy] = useState(tableHeaders[0].property);
    const [sortDirection, setSortDirection] = useState(1);
    const [page, setPage] = useState(0);
    const [mode, setMode] = useState('');
    const [editingPostEntry, setEditingPostEntry] = useState(false);
    const [entryNumberToCopy, setEntryNumberToCopy] = useState(null);
    const { erp } = useUser();

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const entries = await entriesFetcher(accountNumber);
                setEntries(entries);
            } catch (e) {
                const errorCode = e.data?.internalErrorCode;

                const knownErrorCodes = {
                    fiscal_period_mismatch: 'Der er uoverensstemmelse mellem regnskabsperioden, der er registreret i CVR og i bogføringssystemet',
                };

                setError(knownErrorCodes[errorCode] || 'Der opstod en fejl');
            }
            setLoading(false);
        };

        fetchEntries();
    }, [accountNumber, entriesFetcher]);

    const sortColumnClicked = column => {
        if (sortBy === column) {
            setSortDirection(sortDirection * -1);
        } else {
            setSortBy(column);
            setSortDirection(1);
        }
    };

    const sortEntries = useCallback(
        entries => {
            return [...entries].sort((a, b) => {
                const aVal = a[sortBy];
                const bVal = b[sortBy];

                let result;

                if (typeof aVal === 'string') {
                    result = aVal.localeCompare(bVal);
                } else {
                    result = aVal - bVal;
                }

                return result * sortDirection;
            });
        },
        [sortBy, sortDirection],
    );

    const pageSize = 10;
    const sortedBookkeepingEntires = useMemo(() => sortEntries(entries), [entries, sortEntries]);
    const bookkeepingEntriesToShow = useMemo(() => {
        return sortedBookkeepingEntires.slice(page * pageSize, page * pageSize + pageSize)
    }, [sortedBookkeepingEntires, page]);

    // handle fetching attachments that are currently shown in the table
    // (if supported in the users ERP system)
    useEffect(() => {
        if (bookkeepingEntriesToShow.length === 0) return;

        const userChosenERP = erpSystems[erp];
        if (!userChosenERP.supportsFetchingAttachmentsFromBookedEntries) {
            return;
        }

        setLoadingAttachments(true);
        setAttachments([]);

        const entryIds = bookkeepingEntriesToShow.map(entry => entry.entryNumber.toString());

        const { from, to } = getPeriodDatesAsISO(fiscalPeriod);

        getAttachmentsForEntriesInPeriod(accountNumber, from, to, entryIds)
            .then(attachments => setAttachments(attachments))
            .catch(() => toast.error('Bilag på posteringer kunne ikke indlæses...'))
            .finally(() => setLoadingAttachments(false));
    }, [accountNumber, bookkeepingEntriesToShow, erp, fiscalPeriod]);

    const sortedPostEntries = useMemo(
        () => sortEntries(accountPostEntries),
        [accountPostEntries, sortEntries],
    );

    const showSaldo = saldo => {
        const formattedNumber = showDecimalAccounts
            ? formatDecimalNumber(unroundedCurrentYearAmount || saldo)
            : formatNumber(saldo);

        const copyToClipboard = () => {
            navigator.clipboard
                .writeText(formattedNumber)
                .then(() => {
                    toast.success('Saldo kopieret');
                })
                .catch(() => {
                    toast.error('Kunne ikke kopiere');
                });
        };

        return (
            <Popup
                content='Klik for at kopiere'
                trigger={
                    <Button floated='left' onClick={copyToClipboard}>
                        <Icon name='clipboard' />
                        Saldo: {formattedNumber}
                    </Button>
                }
            />
        );
    };

    const renderEntry = ({ entryNumber, date, vatCode, text, amount }, isPostEntry = false) => {
        const associatedAttachments = isPostEntry ? [] : attachments.filter(attachment => {
            return attachment.entryID === entryNumber.toString();
        });

        return (
            <Table.Row
                disabled={disabled}
                style={{
                    opacity: loadingAttachments ? 0.75 : 1,
                    pointerEvents: loadingAttachments ? 'none' : undefined,
                }}
            >
                <Table.Cell>{isPostEntry ? '-' : entryNumber}</Table.Cell>
                <Table.Cell>{formatDate(date)}</Table.Cell>
                <Table.Cell>{vatCode?.trim()}</Table.Cell>
                <Table.Cell>{text}</Table.Cell>
                <Table.Cell textAlign='right'>
                    {showDecimalAccounts ? formatDecimalNumber(amount) : formatNumber(amount)}
                </Table.Cell>
                <Table.Cell textAlign='right' widths={2}>
                    {associatedAttachments.length > 0 && (
                        <AttachmentsViewer
                            accountNumber={accountNumber}
                            attachments={associatedAttachments}
                        />
                    )}
                    {isPostEntry ? (
                        <>
                            <DeleteIcon
                                disabled={disabled}
                                text='Slet efterpostering'
                                onDelete={() => deletePostEntriesByNumber(entryNumber)}
                            />
                            <EditIcon
                                disabled={disabled}
                                text='Rediger efterpostering'
                                onClick={() => {
                                    setMode('edit');
                                    setEditingPostEntry(entryNumber, true);
                                }}
                            />
                        </>
                    ) : (
                        <Popup
                            content='Kopier postering'
                            trigger={
                                <Icon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCreatingPostEntry(true);
                                        setEntryNumberToCopy(entryNumber);
                                        setMode('copy');
                                    }}
                                    name='copy'
                                />
                            }
                        />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderHeaderRow = text => {
        return (
            <Table.Row>
                <Table.Cell colSpan={6}>
                    <strong>{text}</strong>
                </Table.Cell>
            </Table.Row>
        );
    };

    if (loading) {
        return (
            <Modal.Content>
                <strong>
                    <Icon name='spinner' loading />
                    Indlæser posteringer...
                </strong>
            </Modal.Content>
        );
    }

    if (error) {
        return (
            <Modal.Content>
                <strong>
                    <Icon name='warning sign' color='red' /> Posteringer kunne ikke hentes:
                </strong>
                <div>{error}</div>
            </Modal.Content>
        );
    }

    if (editingPostEntry) {
        const entryToEdit = postEntries.find(entry => entry.entryNumber === editingPostEntry);
        return (
            <EditPostEntryForm
                entryToEdit={entryToEdit.affectedAccounts}
                entryNumber={entryToEdit.entryNumber}
                accountNumber={accountNumber}
                accountplan={accountplan}
                postEntries={postEntries}
                sortedPostEntries={sortedPostEntries}
                updatePostEntry={updatePostEntry}
                setEditingPostEntry={setEditingPostEntry}
                onClose={() => {
                    setEditingPostEntry(false);
                }}
                fiscalPeriod={fiscalPeriod}
                mode={mode}
                showDecimalAccounts={showDecimalAccounts}
            />
        );
    }
    if (creatingPostEntry) {
        const entryToCopy = sortedBookkeepingEntires.find(entry => entry.entryNumber === entryNumberToCopy);
        return (
            <CreatePostEntryForm
                onClose={() => {
                    setCreatingPostEntry(false);
                    setEntryNumberToCopy(null);
                }}
                addPostEntry={addPostEntry}
                accountNumber={accountNumber}
                fiscalPeriod={fiscalPeriod}
                accountplan={accountplan}
                entryNumber={entryNumberToCopy}
                currentEntries={entries}
                mode={mode}
                showDecimalAccounts={showDecimalAccounts}
                entryToCopy={entryToCopy}
            />
        );
    }

    const totalPages = Math.ceil(entries.length / pageSize);

    const table = (
        <Table basic='very' compact='very'>
            <Table.Header>
                <Table.Row>
                    {tableHeaders.map(({ text, property, ...props }) => {
                        const isSelectedForSorting = sortBy === property;

                        return (
                            <Table.HeaderCell
                                style={{ cursor: 'pointer' }}
                                onClick={() => property && sortColumnClicked(property)}
                                content={
                                    <span>
                                        {isSelectedForSorting && (
                                            <Icon
                                                name={sortDirection === -1 ? 'caret down' : 'caret up'}
                                            />
                                        )}
                                        {text}
                                    </span>
                                }
                                {...props}
                            />
                        );
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {accountPostEntries?.length > 0 && renderHeaderRow('Efterposteringer')}
                {sortedPostEntries.map(entry => renderEntry(entry, true))}
                {entries?.length > 0 && renderHeaderRow('Bogførte posteringer')}
                {bookkeepingEntriesToShow.map(entry => renderEntry(entry, false))}
                {totalPages > 1 && (
                    <Table.Row>
                        <Table.Cell colSpan={6} textAlign='center'>
                            <Pagination
                                disabled={loadingAttachments}
                                activePage={page + 1}
                                totalPages={totalPages}
                                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );

    return (
        <>
            <Modal.Content scrolling>{table}</Modal.Content>
            <Modal.Actions>
                {showSaldo(currentYearAmount)}
                <Button
                    icon='plus'
                    content='Ny efterpostering'
                    disabled={disabled}
                    onClick={() => {
                        setCreatingPostEntry(true);
                        setMode('create');
                    }}
                />
            </Modal.Actions>
        </>
    );
};

export default EntriesViewer;
