import { toast } from 'react-toastify';
import React, { useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { deleteFile, uploadFile } from 'http/file-storage';
import { deleteLogo } from 'http/accounts';
import { readAsImage, base64ToFile, isFile } from 'util/files';
import useUser from 'util/useUser';
import ImageCropper from 'design/atoms/ImageCropper';
import FileuploadButton from 'design/atoms/FileuploadButton';
import ColoredText from 'design/atoms/ColoredText';

const acceptedTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

const UploadImage = ({ 
    onLogoChanged, 
    customImages, 
    setCustomImages,
    mode = 'image',
    updateUserLogo = false, 
    onImageUploaded = null 
}) => {
    const uploadRef = useRef();
    const user = useUser();
    const [imageBeingCropped, setImageBeingCropped] = useState(null);
    const [latestCrop, setLatestCrop] = useState(null);
    const [working, setWorking] = useState(false);

    const onLogoSelected = async file => {
        if (!isFile(file)) {
            return;
        }
        const image = await readAsImage(file);

        const { naturalWidth, naturalHeight } = image; 

        if (naturalHeight < 150) {
            return toast.error('Billedet skal have en minimumshøjde på 150 pixels');
        }

        const pixelsInImage = naturalWidth * naturalHeight;
        const maxPixels = 1920 * 1080;

        if (pixelsInImage > maxPixels) {
            const scaleFactor = Math.sqrt(maxPixels / pixelsInImage);
            const newWidth = Math.floor(naturalWidth * scaleFactor);
            const newHeight = Math.floor(naturalHeight * scaleFactor);

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, newWidth, newHeight);
            image.src = canvas.toDataURL();
        }

        setImageBeingCropped(image);
    };

    const saveImage = async () => {
        const { height, data } = latestCrop;

        if (height < 150) {
            return toast.error('Udsnittet skal have en højde på mindst 150 pixels');
        }
        
        setWorking(true);

        try {
            const filename = mode === 'logo' ? 'logo.png' : 'image.png';
            const file = await base64ToFile(data, filename, 'image/png');

            const imageFileID = await uploadFile(file);

            
            if (setCustomImages) {
                setCustomImages([imageFileID]);
            }

            setImageBeingCropped(null);
            setLatestCrop(null);
            
            if (onImageUploaded) {
                onImageUploaded(imageFileID);
            }
            
            if (onLogoChanged) {
                onLogoChanged(imageFileID);
            }

            console.log(onLogoChanged, "onLogoChanged");
            
            return imageFileID;
        } catch (e) {
            console.error(`Error saving ${mode}: ${e.message}`);
            toast.error(`Der opstod en fejl under upload af ${mode === 'logo' ? 'logo' : 'billede'}`);
        } finally {
            setWorking(false);
        }
    };
    
    const removeLogo = async () => {
        setWorking(true);
        try {
            if (updateUserLogo && user.logo) {
                await deleteFile(user.logo);
                await deleteLogo();
            }
            
            if (setCustomImages) {
                setCustomImages([]);
            }
            
            // Call callbacks
            if (onLogoChanged) {
                onLogoChanged(null);
            }
            
            if (onImageUploaded) {
                onImageUploaded(null);
            }
            
            toast.success(`${mode === 'logo' ? 'Logo' : 'Billede'} blev fjernet`);
        } catch (e) {
            console.error(`Error removing ${mode}: ${e.message}`);
            toast.error(`${mode === 'logo' ? 'Logo' : 'Billede'} blev ikke fjernet`);
        }
        setWorking(false);
    };

    // Determine labels based on mode
    const uploadLabel = mode === 'logo' ? 'Skift logo' : 'Skift billede';
    const removeLabel = mode === 'logo' ? 'Fjern logo' : 'Fjern billede';
    const saveLabel = mode === 'logo' ? 'Gem logo' : 'Gem billede';

    let content;
    let options;

    if (imageBeingCropped) {
        content = (
            <div style={{ maxWidth: '1024px' }}>
                <ImageCropper
                    image={imageBeingCropped}
                    onCrop={cropped => setLatestCrop(cropped)}
                />
            </div>
        );

        options = (
            <>
                <ColoredText
                    onClick={() => {
                        setLatestCrop(null);
                        setImageBeingCropped(null);
                    }}
                    disabled={working}
                    color='black'
                    icon='arrow left'
                    iconPosition='left'
                    content='Annullér'
                    underlined={false}
                    link
                />
                <Button
                    onClick={saveImage}
                    disabled={working}
                    content={saveLabel}
                    icon='save'
                    primary
                />
            </>
        );
    } else {
        options = (
            <>
                {(user.logo || (customImages && customImages.length > 0)) && (
                    <ColoredText
                        onClick={removeLogo}
                        underlined={false}
                        icon='trash'
                        iconPosition='left'
                        content={removeLabel}
                        disabled={working}
                        link
                    />
                )}
                <FileuploadButton
                    ref={uploadRef}
                    onChange={onLogoSelected}
                    disabled={working}
                    accept={acceptedTypes}
                    trigger={
                        <ColoredText
                            underlined={false}
                            icon='upload'
                            iconPosition='left'
                            content={uploadLabel}
                            disabled={working}
                            link
                        />
                    }
                />
            </>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '1em',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {content}
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                {options}
            </div>
        </div>
    );
};

export default UploadImage;